<template>
  <v-container id="page-apiClient" fluid>
    <base-wee-sketlon-loader
      :loading="state.loading"
      type="table-heading, table-thead, table-tbody, table-tfoot"
      :no="1"
    />

    <!-- Table  -->
    <wee-simple-table
      v-if="!state.loading"
      :headers="fillableHeaders"
      :title="$t('model.api_client.api_client')"
      :tr-list="filteredList"
      :pages="pages"
      :sort="sort"
      @update-search="searchTxt = $event"
      @on-item-click="onItemClick"
      @on-item-delete="onBeforeDeleteItem"
      @on-open-new-form="onOpenNewForm"
      @on-advance-search="advanceSearch"
      @on-reload-page="onReload"
    >
      <!-- <template v-slot:theader></template> 
      <template v-slot:tbody></template> 
      <template v-slot:tpaging></template>  -->
    </wee-simple-table>

    <api-client-form
      v-model="entity"
      :open="openNewForm"
      :processing="isProcessing"
      @close="openNewForm = false"
      @save="onSave"
      @onrefreshtoken="onRefreshToken"
      :edit-mode="editMode"
    />
    <wee-confirm ref="weeConfirmRef"></wee-confirm>
    <wee-toast ref="weeToastRef"></wee-toast>
  </v-container>
</template>

<script>
import ApiClientService from "@/api/ApiClientService";
import useCrudApi from "@/composition/UseCrudApi";
import { toRefs, onBeforeUnmount } from "@vue/composition-api";
export default {
  name: "page-apiClient",
  components: {
    WeeConfirm: () => import("@/components/WeeConfirm"),
    WeeToast: () => import("@/components/WeeToast"),
    WeeSimpleTable: () => import("@/components/WeeSimpleTable"),
    ApiClientForm: () => import("./ApiClientForm")
  },
  setup(props, { refs, root }) {
    const apiClientService = new ApiClientService();
    const tableHeaders = [
      {
        column: "api_name",
        label: "model.api_client.api_name",
        searchable: true,
        sortable: true,
        fillable: true
      },
      {
        column: "by_pass",
        label: "model.api_client.by_pass",
        searchable: true,
        sortable: true,
        fillable: true,
        status: true
      },
      {
        column: "status",
        label: "model.api_client.status",
        searchable: true,
        sortable: true,
        fillable: true,
        status: true
      },
      {
        column: "created_at",
        label: "model.api_client.created_at",
        sortable: true,
        fillable: true,
        date: true
      },
      {
        column: "updated_at",
        label: "model.api_client.updated_at",
        sortable: true,
        fillable: true,
        date: true
      },
      {
        label: "base.tool",
        fillable: true,
        baseTool: true
      }
    ];

    // entity
    const initialItem = {
      id: "",
      api_name: "",
      api_token: "",
      by_pass: false,
      status: false
    };

    const { crud } = useCrudApi(
      refs,
      root,
      apiClientService,
      initialItem,
      tableHeaders
    );

    // fell free to change sort colunm and mode
    // sort.column = "id";
    // sort.mode = "ASC";
    const onRefreshToken = async () => {
      crud.isProcessing = true;
      if (!crud.entity) {
        return;
      }
      const { response, error } = await apiClientService.refreshToken(
        crud.entity.id
      );
      crud.isProcessing = false;
      if (error) {
        refs.weeToastRef.toast(error, { color: "error", timeout: 0 });
        return;
      }
      refs.weeToastRef.toast(response.message, {
        color: response.status ? "success" : "error"
      });
      crud.openNewForm = false;
    };

    onBeforeUnmount(() => {});

    return {
      ...toRefs(crud),
      onRefreshToken
    };
  }
};
</script>
